
import { mapActions, mapGetters } from 'vuex'
import { resStatus } from '@/utils/resUtils'

import Dialog from '@/components/dialog'
import DialogTemplate3 from '@/components/dialog/DialogTemplate3'

export default {
  components: {
    Dialog,
    DialogTemplate3,
  },

  data: () => ({
    dialog: {
      status: false,
      type: '',
    },

    userBaseInfoOk: true,
    userBankCard: [],

    form: {
      bank_no: '',
    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'theme']),
  },

  watch: {
    'dialog.status'(nowStatus) {
      if (!nowStatus) this.form.bank_no = ''
    },
  },

  created() {
    this.set_toolbar_title(this.$t('global.cards'))
    this.getUserBankCardList()
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_user_bankcard_list',
      'delete_user_bank_card',
      'show_alert',
    ]),

    getUserBankCardListSuccess(data) {
      const { bankcards, completed_baseinfo } = data
      if (completed_baseinfo !== 'Y') {
        this.userBaseInfoOk = false
        this.dialog.status = true
        this.dialog.type = 'baseInfo'
      }
      this.userBankCard = bankcards
    },

    async getUserBankCardList() {
      const res = await this.get_user_bankcard_list()
      resStatus(res, this.getUserBankCardListSuccess)
    },
    replaceBankCardNo(bankCarNo) {
      const strLength = bankCarNo.length
      let secret = '＊'
      for (let i = 1; i < strLength - 4; i++) {
        secret += '＊'
      }
      return secret + bankCarNo.substr(strLength - 4, 4)
    },

    showRemoveBankCardDialog(bank_no) {
      this.dialog.status = true
      this.dialog.type = 'removeBankCard'
      this.form.bank_no = bank_no
    },

    deleteUserBankCardSuccess(data) {
      const { bankcards } = data
      this.userBankCard = bankcards

      this.show_alert({
        icon: 'done',
        text: this.$t('global.delete') + this.$t('global.success'),
      })
    },
    deleteUserBankCardFail() {
      this.show_alert({
        icon: 'fail',
        text: this.$t('global.delete') + this.$t('global.fail'),
      })
    },

    async deleteUserBankCard(bank_no = '') {
      this.dialog.status = false
      const updateUserBankCardData = await this.delete_user_bank_card({
        bank_no,
      })
      resStatus(
        updateUserBankCardData,
        this.deleteUserBankCardSuccess,
        this.deleteUserBankCardFail,
      )
    },

    /**
     * 新增銀行卡
     * @date 2021-08-13
     */
    addBankCard() {
      if (this.userBaseInfoOk) return this.$router.push({ path: '/user/add-card' })
      this.dialog.status = true
      this.dialog.type = 'baseInfo'
    },
  },
}

<template>
  <div>
    <v-container class="pt-56">
      <v-row class="pt-3">
        <!-- 使用者銀行卡片 -->
        <template v-if="userBankCard.length > 0">
          <v-col
            v-for="card in userBankCard"
            :key="card._id"
            cols="12"
            class="px-4 py-2"
          >
            <v-container>
              <v-row class="card rounded vipCard1 align-center">
                <!-- 銀行logo -->
                <v-col
                  cols="6"
                  class="card__img"
                >
                  <v-img
                    :src="baseImgUrl + card.image"
                    position="left top"
                    max-height="160"
                    contain
                  />
                </v-col>

                <!-- 銀行說明文字 -->
                <div class="card__info text-right title--text">
                  <div class="card__info__name mb-3">
                    {{ card.bank_name }}
                  </div>
                  <div class="card__info__no">
                    {{ card.bank_no }}
                  </div>
                </div>

                <!-- 刪除按鈕 -->
                <div class="card__close ma-2">
                  <v-btn icon>
                    <v-icon
                      color="card1"
                      @click="showRemoveBankCardDialog(card.bank_no)"
                    >
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
              </v-row>
            </v-container>
          </v-col>
        </template>

        <!-- 新增卡片 -->
        <v-col
          v-if="userBankCard.length < 3"
          cols="12"
          class="px-4 py-2"
        >
          <div class="card2 rounded card">
            <v-container>
              <v-row class="card rounded align-center">
                <div class="card__info text-right title--text">
                  <!-- 新增卡片 btn -->
                  <v-btn
                    text
                    class="pa-0"
                    @click="addBankCard"
                  >
                    <v-icon
                      class="rounded-circle secondary"
                      color="card1"
                    >
                      mdi-plus
                    </v-icon>
                    <span class="ml-3 subtitle-1 title-text">
                      {{ $t('flashMessage.addCard') }}
                    </span>
                  </v-btn>
                </div>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>

      <!-- 說明文字 -->
      <v-row>
        <v-col
          cols="12"
          class="text-center subtitle-2 pt-0 comment--text pb-2"
        >
          <span>
            {{ $t('myCenter.cardLimit') }}
          </span>
        </v-col>
      </v-row>

      <!-- Dialog (如果沒有完善個人資料 / 如果要刪除卡片) -->
      <Dialog
        :dialog="dialog.status"
        :width="280"
        :height="150"
      >
        <!-- 如果沒有完善個人資料 -->
        <template v-if="dialog.type === 'baseInfo'">
          <div
            slot="text"
            class="text px-6"
          >
            {{ $t('myCenter.firstAddBankCard') }}
          </div>

          <v-btn
            slot="btn"
            text
            class="subtitle"
            @click="dialog.status = false"
          >
            {{ $t('global.cancel') }}
          </v-btn>
          <v-btn
            slot="btn"
            to="/user/personal"
            text
            color="primary"
          >
            {{ $t('global.confirmation') }}
          </v-btn>
        </template>

        <!-- 如果要刪除卡片 -->
        <template v-if="dialog.type === 'removeBankCard'">
          <div
            slot="text"
            class="text px-6"
          >
            {{ $t('flashMessage.confirmDeleteBankCard') }}
          </div>
          <v-spacer />
          <v-btn
            slot="btn"
            text
            class="subtitle"
            @click="dialog.status = false"
          >
            {{ $t('global.cancel') }}
          </v-btn>
          <v-btn
            slot="btn"
            text
            color="primary"
            @click="deleteUserBankCard(form.bank_no)"
          >
            {{ $t('global.confirmation') }}
          </v-btn>
        </template>
      </Dialog>
    </v-container>
  </div>
</template>

<script>
import cardsMixin from '../mixins/cardsMixin'

export default {
  mixins: [cardsMixin],
}
</script>

<style lang="scss" scoped>
.card {
	position: relative;
	height: 180px;

	&__img {
		opacity: 0.3;
	}

	&__info {
		position: absolute;
		right: 24px;
		bottom: 25px;
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
	}
}
</style>
